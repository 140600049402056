<template>
  <div>
    <span v-html="value.shortText" :id="'id-' + value.id"></span>
    <b-tooltip :target="'id-' + value.id" placement="bottom">
      {{ value.longText }}
    </b-tooltip>
  </div>
</template>

<script>
  export default {
    name: 'tdColTooltip',
    props: ['value']
  }
</script>

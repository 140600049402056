<template>
  <div style="position: relative">
    <button class="btn-legam-xs btn-legam-dt-filter table-filter" :class="filteredClass" @click="show = !show">Filter</button>
    <div class="filter-box p-3" v-if="show">
      <!-- <b-form-group
        id="language-1"
        label="Language:"
        label-for="language"
      >
        <b-form-select id="language" v-model="language" :options="languageOptions"></b-form-select>
      </b-form-group> -->
      <b-form-group
        id="scripta-1"
        label="Scripta:"
        label-for="scripta"
      >
        <!-- <b-form-select id="scripta" v-model="scripta" :options="scriptaOptions"></b-form-select> -->
        <multiselect
          v-model="selectedScriptas"
          id="scripta"
          :options="scriptaOptions"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Pick some"
          label="text"
          track-by="text"
          :preselect-first="false">
        </multiselect>
      </b-form-group>
      <button class="btn-legam-xs btn-legam-dt-filter" @click="show = false">Close</button>
      <button class="btn-legam-xs btn-legam-dt-filter" @click="clearFilter">Clear</button>
    </div>
  </div>
</template>

<style scoped>
  .filtered {
    background-color: #6c757d;
    border: 1px solid #ced4da;
  }

  .not-filtered {
    color: #6c757d;
    background-color: #fff;
    border: 1px solid #ced4da;
  }

  .filter-box {
    position: absolute;
    background-color: #e8e8e8;
    padding: 10px;
    border-radius: 3px;
    z-index: 10;
    top: 10px;
    left: 20px;
    width: 350px;
  }
</style>

<script>
  import { mapState } from 'vuex'
  import Multiselect from 'vue-multiselect'
  import config from '@/config.js'

  export default {
    name: 'filterScriptaLanguageComponentDatatables',
    props: ['value'],
    data(){
      return {
        show: false,
        // scripta: null,
        selectedScriptas: [],
        language: null,
        config: config,
      }
    },
    components: {
      Multiselect
    },
    methods: {
      clearFilter(){
        this.show = false
        this.selectedScriptas = []
      }
    },
    computed: {
      ...mapState({
        scriptas: state => state.common.scriptas,
        // languages: state => state.common.languages
      }),
      // languageOptions(){
      //   return this.languages.map(language => {
      //     return {
      //       text: `${language.name}, ${language.code}`,
      //       value:  language.code
      //     }
      //   })
      // },
      scriptaOptions(){
        return this.scriptas.map(scripta => {
          let lang = (scripta.language ? ` (${scripta.language.name})` : '')
          return {
            text: `${scripta.code}${lang}`,
            value:  scripta.code
          }
        })
      },
      filteredClass(){
        return (this.selectedScriptas.length > 0 ? 'filtered' : 'not-filtered')
      }
    },
    mounted () {
      // this.$store.dispatch('loadLanguages')
      this.$store.dispatch('loadScriptas')
    },
    watch: {
      "selectedScriptas"(){
        let selectedScriptas = this.selectedScriptas.map(scripta => scripta.value)
        if (!config.editable) {
          selectedScriptas = this.selectedScriptas.map(scripta => scripta.text)
        }
        this.$emit('update', selectedScriptas)
      }
    }
  }
</script>
